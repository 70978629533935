.scroll-to-bottom {
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 0;
  background-clip: content-box;
  z-index: 3;
}

.scroll-to-bottom::before {
  content: '\2193';
}

.scroll-to-bottom * {
  background: transparent;
  width: auto;
  margin: 0;
  padding: 0;
}

.messages::-webkit-scrollbar {
  width: 5px;
}

.messages::-webkit-scrollbar-track {
  background: #333;
}

.messages::-webkit-scrollbar-thumb {
  background: #888;
}

.conversation-ended {
  background-color: black;
  color: white;
  position: fixed;
  bottom:1%;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10px;
  margin: 5% 5% 5% 5%;
  z-index: 9999;
  width: 100%;
}

.conversation-ended button {
  padding: 10px;
  margin: 10px 10% 10px 20px;
  font-size: 1.3rem;
}


@media (max-width: 768px) {
  .chatImg {
      height: 60px;
      padding: 10px;
      display: inline-block;
      margin: 0 0 3em 0;
  }

  .scroll-to-bottom {
      bottom: 70px;
      width: 35px;
      height: 35px;
      margin: 0;
  }

  .messages {
      height: calc(100vh - 10vh);
      min-height: 100px;
      overflow-y: auto;
  }

  .chat-container {
      height: calc(100vh);
      padding-top: 0;
      max-width: 100%;
  }

  .message {
      max-width: 75%;
  }

  .message-form {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1000;
      background-color: #fff;
      box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
      border-top: 1px solid #999;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 0;
      border-radius: 0;
  }

  .quick-replies {
      bottom: 40px;
  }

  .message-form input {
      flex: 1;
      margin-right: 10px;
      outline: none;
      padding: 10px;
      border-radius: 5px 0 0 5px;
  }

  .message-form button {
      padding: 10px;
      border: none;
      color: white;
      border-radius: 5px 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  }

  .scroll-to-bottom {
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 35px;
  }
}

@media (max-width: 480px) {
  .scroll-to-bottom {
      bottom: 120px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      z-index: 9999;
  }

  .quick-replies {
      bottom: 35px;
  }

  .messages {
      height: calc(100vh - 10vh);
      min-height: 100px;
      overflow-y: auto;
  }

  .chat-container {
      height: calc(100vh);
      padding-top: 0;
      max-width: 100%;
  }

  .message {
      max-width: 70%;
  }

  .message-form input {
      width: 80%;
  }

  .message-form input, .message-form button {
      padding: 8px;
  }
}